<script>
	import CartItemPrice from './CartItemPrice.svelte'
	import PriceSummary from './PriceSummary.svelte'

	export let items
	export let otherItems
	export let totalPrice
</script>

<div class="cartSummary">
	{#each items as item, index}
		{#if item.quantity > 0}
			<div class="cartSummary-left">
				<div>{item.title}</div>
				<div>{item.quantity} ks</div>
			</div>
			<div class="cartSummary-right">
				<CartItemPrice {...item.price} />
			</div>
			{#each item.complements as complement}
				{#if complement.quantity}
					<div class="cartSummary-left">
						<div>{complement.title}</div>
					</div>
					<div class="cartSummary-right">
						<CartItemPrice {...complement.price}/>
					</div>
				{/if}
			{/each}
			<hr class="cartSummary-separator" />
		{/if}
	{/each}
	{#each otherItems as item}
		{#if item && !item.warning}
			<div class="cartSummary-left">
				<div>{item.label}</div>
			</div>
			<div class="cartSummary-right">
				{#if item.price}
					<CartItemPrice original={item.price} discounted={false} />
				{/if}
			</div>
			<hr class="cartSummary-separator" />
		{/if}
	{/each}
	<div class="cartSummary-totalPrice">
		<PriceSummary
			totalPrice={totalPrice.formatted}
			vatPrice={totalPrice.vatFormatted}
		/>
	</div>
</div>
